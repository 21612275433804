import React from 'react';
import {Link} from 'gatsby';
import {OutboundLink} from 'gatsby-plugin-google-analytics';

import {Graphic} from 'components';


export default function Nav() {
    return (
        <nav>
            <Link to="/">
                <Graphic name="fundamental"/>
                <b>{'Fundamental'}</b>
                <time dateTime="2019-07-01">{'est. 2019'}</time>
            </Link>
            <OutboundLink href="mailto:hello@fundamental.shop">
                <Graphic name="email"/>
                {'Contact us'}
                <em>{'hello@fundamental.shop'}</em>
            </OutboundLink>
        </nav>
    );
}
