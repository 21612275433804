// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';

import {Sprite} from 'assets';


const Graphic = (props) => Sprite(props);

Graphic.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Graphic;
