import React from 'react';
import PropTypes from 'prop-types';

import {Head} from 'components';

import './screen.less';


const Layout = ({title, children}) => {
    return(
        <>
            <Head title={title}/>
            <main>{children}</main>
        </>
    );
};

Layout.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
    title: 'Fundamental: A Software and Graphic Design Workshop',
};

export default Layout;
