import React from 'react';
import {Helmet} from 'react-helmet';


const icons = [16, 32, 96, 120, 152, 167, 180];
const themeColour = '#7E001C';

const Head = (props) => {
    return(
        <Helmet title={props.title}>
            <html lang="en"/>
            <meta name="description" content="Fundamental is a couple of mates who build web apps, APIs, libraries, design systems and more. Made by James Toohey and Chris Sealey."/>
            <meta name="theme-color" content={themeColour}/>
            <meta property="og:title" content="A Software and Graphic Design Workshop"/>
            <meta property="og:image" content="/images/og-image.png"/>
            {icons.map((icon, i) =>
                <link
                    href={`/icons/icon-${icon}.png`}
                    key={i}
                    rel={icon > 96 ? 'apple-touch-icon' : 'icon'}
                    sizes={`${icon}x${icon}`}
                />
            )}
            <link color={themeColour} href={'/icons/icon-mask.svg'} rel="mask-icon"/>
            <link
                as="font"
                crossorigin
                href="https://fonts.gstatic.com/s/goudybookletter1911/v9/sykt-z54laciWfKv-kX8krex0jDiD2HbY6IJshzWRYEHAQ.woff2"
                rel="preload"
                type="font/woff2"
            />
        </Helmet>
    );
};

export default Head;
