import React from 'react';
import PropTypes from 'prop-types';


const Image = (props) => {
    const { alt, src, src2x } = props;
    return (
        <img
            alt={alt}
            src={src}
            srcSet={src2x && `${src} 1x, ${src2x} 2x`}
        />
    );
};

Image.propTypes = {
    alt: PropTypes.string,
    src: PropTypes.string.isRequired,
    src2x: PropTypes.string,
};

Image.defaultProps = {
    alt: '',
};

export default Image;
